import React, { ReactNode } from 'react';
import { Flex, SxStyleProp } from 'rebass';
import { StyleObject } from 'types/styles';

export const Container = ({
  children,
  containerStyle = {},
  bodyStyle = {},
}: {
  children: ReactNode;
  containerStyle?: SxStyleProp;
  bodyStyle?: SxStyleProp;
}) => (
  <Flex sx={{ ...styles.body, ...bodyStyle }}>
    <Flex sx={{ ...styles.container, ...containerStyle }}>{children}</Flex>
  </Flex>
);

const styles: StyleObject = {
  body: {
    alignItems: 'center',
    flexDirection: 'column',
    pt: ['96px', '120px'],
    width: '100%',
  },
  container: {
    flexDirection: 'column',
    // maxWidth: '1000px',
    width: '100%',
  },
};
