import { LinkWrapper } from 'components/common/LinkWrapper';
import { Colors } from 'constants/Colors';
import { useIsMobile } from 'hooks/useIsMobile';
import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Image, Text } from 'rebass';
import { StyleObject } from 'types/styles';
import { signInWithGoogle, signInWithKakao } from 'utils/Firebase/auth';
import Loading from 'react-spinners/ClipLoader';
import { defaultToast, noticeToast } from 'utils/Alert';

export interface SignInViewProps {
  initialLoading?: boolean;
}

export const SignInView = ({ initialLoading = false }: SignInViewProps) => {
  const isMobile = useIsMobile();

  const [loading, setLoading] = useState(false);
  const [kakaoloading, setKakaoloading] = useState(false);

  useEffect(() => {
    setLoading(initialLoading);
    setKakaoloading(initialLoading);
  }, [initialLoading]);

  const onClickSignIn = useCallback(() => {
    if (loading) {
      defaultToast('로그인 처리중입니다. 조금만 기다려주세요!');
      return;
    }
    if (isMobile && window.navigator.userAgent.toLowerCase().includes('naver')) {
      noticeToast(
        '보안 문제로 네이버 앱에서는 구글 로그인이 불가능합니다. 다른 브라우저를 이용해주세요.',
      );
    }
    if (isMobile && window.navigator.userAgent.toLowerCase().includes('kakaotalk')) {
      noticeToast(
        '보안 문제로 카카오톡 앱에서는 구글 로그인이 불가능합니다. 다른 브라우저를 이용해주세요.',
      );
    }
    setLoading(true);
    signInWithGoogle();
  }, [isMobile, loading]);

  const onClickKakaoSignIn = useCallback(() => {
    if (kakaoloading) {
      defaultToast('로그인 처리중입니다. 조금만 기다려주세요!');
      return;
    }
    setKakaoloading(true);
    signInWithKakao();
  }, [kakaoloading]);

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.slogan}>
        <Text sx={styles.sloganText}>영화로운 당신의</Text>
        <Text sx={styles.sloganText}>사생활을 위해 🎞️</Text>
      </Flex>
      <Flex sx={styles.kakaoSigninButton} onClick={onClickKakaoSignIn}>
        {!kakaoloading && <Image src="/kakao_login_icon.svg" sx={styles.kakaoIcon} />}
        {!kakaoloading && <Text sx={styles.kakaoButtonText}>카카오로 1초만에 시작하기</Text>}
        {kakaoloading && <Loading size={14} color="#381F1E" />}
      </Flex>
      <Flex sx={styles.bothLineText}>다른 SNS 계정으로 로그인</Flex>
      <Flex sx={styles.googleSigninButton} onClick={onClickSignIn}>
        {!loading && <Image src="/google_logo.svg" sx={styles.googleIcon} />}
        {!loading && <Text sx={styles.buttonText}>구글 로그인</Text>}
        {loading && <Loading size={14} color="black" />}
      </Flex>
      <Text sx={styles.infoText}>새로운 계정은 아래의 약관이 적용됩니다.</Text>
      <Flex>
        <LinkWrapper
          href="https://7pictures.notion.site/8a6d7a7dc2864a31ab1c5f0c15a328f6"
          target="_blank">
          <Text sx={styles.linkText}>이용약관</Text>
        </LinkWrapper>
        <LinkWrapper
          href="https://7pictures.notion.site/63558f0ec0dd45b4a8220800bcc15b78"
          target="_blank">
          <Text sx={styles.linkText}>개인정보 처리방침</Text>
        </LinkWrapper>
      </Flex>
    </Flex>
  );
};

const styles: StyleObject = {
  bothLineText: {
    ':after': {
      bg: Colors.grayc4,
      content: '""',
      flexGrow: 1,
      fontSize: '0px',
      height: '0.5px',
      lineHeight: '0px',
      ml: '16px',
    },
    ':before': {
      bg: Colors.grayc4,
      content: '""',
      flexGrow: 1,
      fontSize: '0px',
      height: '0.5px',
      lineHeight: '0px',
      mr: '16px',
    },
    alignItems: 'center',
    color: Colors.grayc4,
    flexBasis: '100%',
    fontSize: '13px',
    margin: '25px 0',
    maxWidth: '100%',
    width: '350px',
  },
  buttonText: {
    color: Colors.gray82,
    fontSize: '14px',
    ml: '10px',
  },
  container: {
    alignItems: 'center',
    flexDirection: 'column',
    px: '30px',
    py: '100px',
  },
  googleIcon: {
    height: '24px',
    width: '24px',
  },
  googleSigninButton: {
    alignItems: 'center',
    bg: 'white',
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: '5px',
    cursor: 'pointer',
    height: '48px',
    justifyContent: 'center',
    mb: '20px',
    width: '180px',
  },
  infoText: {
    color: Colors.gray99,
    fontSize: '13px',
    fontWeight: 400,
    mb: '10px',
    mt: '20px',
  },
  kakaoButtonText: {
    color: '#381F1E',
    fontSize: '14px',
    fontWeight: 700,
    ml: '10px',
  },
  kakaoIcon: {
    height: '18px',
    width: '18px',
  },
  kakaoSigninButton: {
    alignItems: 'center',
    bg: '#FEE500',
    border: `1px solid #FEE500`,
    borderRadius: '5px',
    cursor: 'pointer',
    height: '48px',
    justifyContent: 'center',
    maxWidth: '100%',
    mt: ['20px', '10px'],
    width: '350px',
  },
  linkText: {
    color: Colors.gray82,
    fontSize: '13px',
    fontWeight: 400,
    mx: '7px',
    textDecoration: 'underline',
  },
  logo: {
    height: ['56px', '82px'],
    width: ['62px', '86px'],
  },
  slogan: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    mb: '60px',
  },
  sloganText: {
    color: Colors.gray11,
    fontSize: '24px',
    fontWeight: 700,
    mb: '6px',
  },
};
