/* eslint-disable no-underscore-dangle,@typescript-eslint/no-non-null-assertion */
import mixpanel, { Mixpanel } from 'mixpanel-browser';

export class MixpanelTracker {
  private static _instance: MixpanelTracker;

  private mixpanel: Mixpanel;

  private constructor() {
    const isProduction = process.env.NODE_ENV === 'production';
    this.mixpanel = mixpanel.init(
      isProduction
        ? process.env.PRODUCTION_MIXPANEL_TOKEN!
        : process.env.DEVELOPMENT_MIXPANEL_TOKEN!,
      {
        debug: !isProduction,
        persistence: 'localStorage',
        secure_cookie: true,
        track_pageview: 'url-with-path',
      },
      'mixpanel_tracker_web',
    );
  }

  public static get instance(): MixpanelTracker {
    if (!MixpanelTracker._instance) {
      MixpanelTracker._instance = new MixpanelTracker();
    }
    return MixpanelTracker._instance;
  }

  public static reset() {
    MixpanelTracker.instance.mixpanel.reset();
  }

  public static trackEvent(event: string, properties?: any) {
    MixpanelTracker.instance.mixpanel.track(event, {
      ...properties,
    });
  }

  public static identify(id: string) {
    MixpanelTracker.instance.mixpanel.identify(id);
  }

  public static setPeople(properties: any) {
    MixpanelTracker.instance.mixpanel.people.set(properties);
  }
}
