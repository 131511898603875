import { SignInView } from 'components/My/SignInView';
import { Colors } from 'constants/Colors';
import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Image, Text } from 'rebass';
import { useRecoilState } from 'recoil';
import { signInModalVisibleState } from 'src/recoil/UIState';
import { StyleObject } from 'types/styles';

export const SignInModal = () => {
  const [show, setShow] = useState(false);
  const [modalVisible, setModalVisible] = useRecoilState(signInModalVisibleState);
  const animationEnd = useCallback(() => {
    if (!show) {
      setModalVisible(false);
    }
  }, [setModalVisible, show]);

  const onClickClose = useCallback(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    if (modalVisible) {
      setShow(true);
    }
  }, [modalVisible]);

  if (!modalVisible) {
    return null;
  }
  return (
    <Flex sx={{ ...styles.container, opacity: show ? 1 : 0 }} onTransitionEnd={animationEnd}>
      <Flex sx={styles.wrapper}>
        <Flex sx={styles.closeButton} onClick={onClickClose}>
          <Image src="/icon_close.svg" sx={styles.closeIcon} />
        </Flex>
        <SignInView />
      </Flex>
    </Flex>
  );
};

const styles: StyleObject = {
  buttonText: {
    color: Colors.gray82,
    fontSize: '14px',
    ml: '10px',
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  closeIcon: {
    height: '32px',
    width: '32px',
  },
  container: {
    alignItems: 'center',
    bg: 'rgba(255, 255, 255, 0.9)',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    transition: 'all 0.3s ease 0s',
    zIndex: 9999,
  },
  googleIcon: {
    height: '24px',
    width: '24px',
  },
  googleSigninButton: {
    alignItems: 'center',
    bg: 'white',
    border: `1px solid ${Colors.bluishGrayda}`,
    borderRadius: '24px',
    cursor: 'pointer',
    height: '48px',
    justifyContent: 'center',
    mt: ['20px', '10px'],
    width: '300px',
  },
  infoText: {
    color: Colors.gray99,
    fontSize: '13px',
    fontWeight: 400,
    mb: '10px',
    mt: '20px',
  },
  linkText: {
    color: Colors.gray82,
    fontSize: '13px',
    fontWeight: 400,
    mx: '7px',
    textDecoration: 'underline',
  },
  logo: {
    height: ['56px', '82px'],
    width: ['62px', '86px'],
  },
  wrapper: {
    alignItems: 'center',
    bg: ['transparent', 'white'],
    border: ['none', `1px solid ${Colors.bluishGrayda}`],
    borderRadius: '4px',
    flexDirection: 'column',
    height: ['100%', 'auto'],
    position: 'relative',
    width: ['100%', '440px'],
  },
};
