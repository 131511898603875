import { useEffect, useState } from 'react';
import { isMobile as isMobileDetected } from 'react-device-detect';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(isMobileDetected);
  const handleResize = () => {
    setIsMobile(window && window.innerWidth < 640);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};
