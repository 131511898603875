import React from 'react';
import { Flex, Text, Image, SxStyleProp } from 'rebass';
import { StyleObject } from 'types/styles';
import { LinkWrapper } from 'components/common/LinkWrapper';

interface LineBannerWithTextAndBgProps {
  text: string;
  link: string;
  target?: string;
  iconSrc?: string;
  onClickClose: () => void;
  bgStyle?: SxStyleProp;
  textStyles?: SxStyleProp;
  filterSvg?: string;
}

export const LineBannerWithTextAndBg = ({
  text,
  link,
  target,
  iconSrc,
  onClickClose,
  bgStyle,
  textStyles,
  filterSvg,
}: LineBannerWithTextAndBgProps) => {
  const parsedText = React.createElement('div', {
    dangerouslySetInnerHTML: { __html: text },
  });

  return (
    <Flex sx={{ ...styles.container, ...bgStyle }}>
      <Flex
        sx={{
          ...styles.container,
          backgroundImage: filterSvg ?? 'none',
        }}>
        <Flex sx={styles.wrapper}>
          <Flex sx={{ mr: 'auto', width: '20px' }} />
          <LinkWrapper href={link} target={target ?? '_blank'}>
            <Flex alignItems="center">
              <Text sx={{ fontSize: '12px', mr: '4px' }}>👀</Text>
              <Text sx={{ ...styles.bannerText, ...textStyles }}>{parsedText}</Text>
            </Flex>
          </LinkWrapper>
          <Image
            sx={styles.closeButton}
            src={iconSrc ?? '/icon_close.svg'}
            onClick={onClickClose}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

const styles: StyleObject = {
  bannerText: {
    fontSize: ['14px', '15px'],
    fontWeight: ['700', '700'],
    textAlign: 'center',
  },
  closeButton: {
    cursor: 'pointer',
    height: '20px',
    ml: 'auto',
    mr: ['8px', '0px'],
    width: '20px',
    p: '2px',
    border: '1px solid #DCDCDC',
    borderRadius: '100px',
  },
  container: {
    alignItems: 'center',
    height: '45px',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  wrapper: {
    alignItems: 'center',
    height: '45px',
    justifyContent: 'center',
    maxWidth: '1000px',
    position: 'relative',
    width: ['100%', '100%'],
  },
};
