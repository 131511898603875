import React from 'react';
import { Flex, Text } from 'rebass';
import { StyleObject } from 'types/styles';
import { Colors } from 'constants/Colors';
import { LinkWrapper } from './LinkWrapper';

export const Footer = () => (
  <Flex sx={styles.container}>
    <Flex sx={styles.wrapper}>
      <Flex flexWrap="wrap">
        <LinkWrapper
          href="https://7pictures.notion.site/676f175466d340c5b9e8a5621ee1d96c"
          target="_blank">
          <Text sx={styles.linkText}>About</Text>
        </LinkWrapper>
        <LinkWrapper
          href="https://7pictures.notion.site/FAQ-4b03802fced9468789ce3cebb1953d1b?pvs=4"
          target="_blank">
          <Text sx={styles.linkText}>FAQ</Text>
        </LinkWrapper>
        <LinkWrapper href="https://bit.ly/nfyg-host" target="_blank">
          <Text sx={styles.linkText}>모임장 모집</Text>
        </LinkWrapper>
        <LinkWrapper href="https://bit.ly/nfyg-host-event" target="_blank">
          <Text sx={styles.linkText}>이벤트 모집</Text>
        </LinkWrapper>
        <LinkWrapper href="https://bit.ly/nfyg-space" target="_blank">
          <Text sx={styles.linkText}>공간 모집</Text>
        </LinkWrapper>
        <LinkWrapper href="https://bit.ly/nfyg-team" target="_blank">
          <Text sx={styles.linkText}>채용</Text>
        </LinkWrapper>
      </Flex>
      <LinkWrapper href="/">
        <Text sx={styles.title}>당신의 영화같은 사생활, 넷플연가</Text>
      </LinkWrapper>
      <Text sx={styles.description}>
        주식회사 세븐픽쳐스 | 대표자 전희재 | 사업자등록번호 342-81-00499
        <br />
        서울시 마포구 신촌로34길 11 아현시티 B1
      </Text>
    </Flex>
  </Flex>
);

const styles: StyleObject = {
  container: {
    borderTop: `1px solid #787878`,
    flexDirection: 'column',
    mb: '60px',
    mt: '20px',
    mx: ['20px', 'auto'],
    pt: '10px',
    width: ['calc(100% - 40px)', '100%'],
  },
  description: {
    color: Colors.gray99,
    fontSize: ['13px', '14px'],
    mt: '10px',
    lineHeight: '1.4',
  },
  linkText: {
    color: Colors.gray82,
    fontSize: ['13px', '14px'],
    fontWeight: 'bold',
    mr: ['20px', '40px'],
    py: '10px',
  },
  title: {
    color: Colors.gray99,
    fontSize: ['13px', '14px'],
    fontWeight: 500,
    mb: '10px',
    mt: '20px',
  },
  wrapper: {
    flexDirection: 'column',
    maxWidth: '1000px',
    mx: 'auto',
    width: '100%',
  },
};
