import { Nav } from 'components/common/Nav';
import Head from 'next/head';
import React, { ReactChild, useCallback, useEffect, useMemo, useState } from 'react';
import { Footer } from 'components/common/Footer';
import { Container } from 'components/common/Container';
import { SystemStyleObject } from '@styled-system/css';
import { useIsMobile } from 'hooks/useIsMobile';
import { useRouter } from 'next/router';
import { SignInModal } from './SignInModal';
import { BaseApi } from 'utils/Api/BaseApi';
import { WelcomeInterviewPromotionPopup } from 'components/common/WelcomeInterviewPromotionPopup';

interface PageMeta {
  title: string;
  description?: string;
  ogUrl: string;
}

const META = {
  description: '당신의 영화같은 사생활',
  ogUrl: '/nfyg_temp_og.png',
  title: '넷플연가 - 당신의 영화같은 사생활',
};

interface PageTemplateProps {
  children: ReactChild | ReactChild[];
  containerStyle?: SystemStyleObject;
  pageMeta?: PageMeta;
  isNavTransition?: boolean;
}

export const PageTemplate = ({
  children,
  containerStyle,
  pageMeta = META,
  isNavTransition = false,
}: PageTemplateProps) => {
  useEffect(() => {});
  const router = useRouter();
  const isMobile = useIsMobile();

  const [showLineBanner, setLineShowBanner] = useState(false);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    BaseApi.setReferrer(window.location.href);
  }, []);

  const onCloseLineBanner = useCallback(() => {
    localStorage.setItem('isWelcomeInterviewBannerClosed', 'true');
    setLineShowBanner(false);
  }, []);

  useEffect(() => {
    const isLineBannerClosed = localStorage.getItem('isWelcomeInterviewBannerClosed');
    if (isLineBannerClosed === 'true') {
      setLineShowBanner(false);
    } else {
      setLineShowBanner(true);
    }
  }, [isMobile]);

  return (
    <Container
      containerStyle={containerStyle}
      bodyStyle={showLineBanner ? { pt: ['141px', '165px'] } : { pt: ['96px', '120px'] }}
      // 띠 배너가 있을 때와 없을 때의 bodyStyle를 다르게 적용
      // bodyStyle={{ pt: ['96px', '120px'] }}
      >
      <Head>
        <title>{pageMeta.title}</title>
        <meta name="description" content={pageMeta.description} />
        <meta name="author" content="넷플연가" />
        <meta property="og:title" content={pageMeta.title} />
        <meta property="og:description" content={pageMeta.description} />
        <meta property="og:image" content={pageMeta.ogUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:image:alt" content={pageMeta.ogUrl} />
        <meta name="keywords" content="넷플연가, 커뮤니티, 소셜살롱, 모임, 소셜" />
      </Head>
      {isClient && (
      <>
        <Nav
          isNavTransition={isNavTransition}
          showLineBanner={showLineBanner}
          onCloseLineBanner={onCloseLineBanner}
        />
        {children}
        <Footer />
        <SignInModal />
        <WelcomeInterviewPromotionPopup />
      </>
      )}
    </Container>
  );
};
