import Swal from 'sweetalert2';
import { isMobile } from 'react-device-detect';
import { Colors } from 'constants/Colors';

export const confirmAlert = (
  title: string,
  html: string,
  confirmButtonText: string,
  denyButtonText?: string,
) =>
  Swal.fire({
    confirmButtonColor: Colors.gray11,
    confirmButtonText,
    customClass: {
      confirmButton: 'confirm-button',
      denyButton: 'deny-button',
      htmlContainer: 'confirm-text',
      popup: 'confirm-popup',
      title: 'confirm-title',
    },
    denyButtonColor: Colors.bluishGrayda,
    denyButtonText: denyButtonText ?? '아니오',
    html,
    reverseButtons: true,
    showDenyButton: true,
    title,
    width: isMobile ? '100%' : '450px',
  });

export const defaultAlertWithDeny = (title: string) =>
  Swal.fire({
    confirmButtonColor: Colors.gray11,
    confirmButtonText: '확 인',
    customClass: {
      confirmButton: 'confirm-button',
      denyButton: 'deny-button',
      popup: 'default-popup',
      title: 'default-title',
    },
    denyButtonColor: Colors.bluishGrayda,
    denyButtonText: '취 소',
    didClose: () => true,
    reverseButtons: true,
    showDenyButton: true,
    title,
    width: isMobile ? '100%' : '450px',
  });

export const defaultAlert = (
  title: string,
  confirmButtonText?: string,
) =>
  Swal.fire({
    confirmButtonColor: Colors.gray11,
    confirmButtonText: confirmButtonText ?? '확 인',
    customClass: {
      confirmButton: 'default-button',
      popup: 'default-popup',
      title: 'default-title',
    },
    didClose: () => true,
    title,
    width: isMobile ? '100%' : '450px',
  });

export const defaultAlertIncludingHtml = (
  title: string,
  html: string,
  confirmButtonText?: string,
) =>
  Swal.fire({
    confirmButtonColor: Colors.gray11,
    confirmButtonText,
    customClass: {
      confirmButton: 'default-button',
      htmlContainer: 'confirm-text',
      popup: 'confirm-popup',
      title: 'confirm-title',
    },
    html,
    title,
    width: isMobile ? '100%' : '450px',
  });

export const defaultAlertWithText = (title: string, html: string) =>
  Swal.fire({
    confirmButtonColor: Colors.gray11,
    confirmButtonText: '확 인',
    customClass: {
      confirmButton: 'default-button',
      htmlContainer: 'default-with-text',
      popup: 'default-popup',
      title: 'default-with-title',
    },
    didClose: () => true,
    html,
    title,
    width: isMobile ? '100%' : '450px',
  });

export const defaultToast = (title: string) => {
  const Toast = Swal.mixin({
    position: 'bottom',
    showConfirmButton: false,
    timer: 2500,
    toast: true,
  });

  Toast.fire({
    background: Colors.gray24,
    color: 'white',
    customClass: {
      title: 'toast-title',
    },
    padding: '6px',
    title,
    width: isMobile ? '100%' : '510px',
  });
};

export const noticeToast = (title: string) => {
  const Toast = Swal.mixin({
    position: 'bottom',
    timer: 5000,
    toast: true,
  });

  Toast.fire({
    background: Colors.gray24,
    color: 'white',
    confirmButtonText: '확인',
    customClass: {
      confirmButton: 'noti-confirm-button',
      image: 'noti-icon',
      popup: 'noti-popup',
      title: 'noti-title',
    },
    imageHeight: '18px',
    imageUrl: '/triangle_noti_icon.svg',
    imageWidth: '18px',
    padding: '6px',
    title,
    width: isMobile ? '100%' : '510px',
  });
};

export const imagePopup = (src: string) =>
  Swal.fire({
    customClass: {
      image: 'popup-image',
      popup: 'popup-image-popup',
    },
    imageHeight: 'auto',
    imageUrl: src,
    showConfirmButton: false,
    width: isMobile ? '90%' : '450px',
  });
