import React, { useEffect, useState } from 'react';
import { Box, Flex, Text, Image } from 'rebass';
import { useRouter } from 'next/router';
import { useIsMobile } from 'hooks/useIsMobile';
import { StyleObject } from 'types/styles';
import { nl2br } from 'utils/TextUtils';
import { InfiniteHorizontalLoop } from './InfiniteHorizontalLoop';

export const WelcomeInterviewPromotionPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const router = useRouter();
  const isMobile = useIsMobile();

  useEffect(() => {
    const checkPopupStatus = () => {
      const isHiddenForSession = sessionStorage.getItem('promotionPopupHidden');
      if (isHiddenForSession === 'true') return;

      const lastClosedTime = localStorage.getItem('promotionPopupClosedTime');
      if (lastClosedTime) {
        const now = new Date().getTime();
        const timeDiff = now - parseInt(lastClosedTime, 10);
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        if (hoursDiff < 168) return;
      }

      setIsOpen(true);
      setTimeout(() => setIsVisible(true), 100);
    };

    checkPopupStatus();
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      sessionStorage.setItem('promotionPopupHidden', 'true');
      setIsOpen(false);
    }, 300);
  };

  const handleCloseForSevenDays = () => {
    setIsVisible(false);
    setTimeout(() => {
      localStorage.setItem('promotionPopupClosedTime', new Date().getTime().toString());
      setIsOpen(false);
    }, 300);
  };

  const handleClickDetail = () => {
    setIsVisible(false);
    setTimeout(() => {
      localStorage.setItem('promotionPopupClosedTime', new Date().getTime().toString());
      router.push('/welcome-interview');
      setIsOpen(false);
    }, 300);
  };

  if (!isOpen) return null;

  return (
    <Box sx={styles.overlay} style={{ opacity: isVisible ? 1 : 0 }}>
      <Flex 
        sx={isMobile ? styles.mobileContainer : styles.pcContainer}
        style={{ 
          transform: isMobile 
            ? `translateY(${isVisible ? '0' : '100%'})` 
            : 'none',
        }}
      >
        <Flex sx={styles.content}>
          <Flex sx={styles.closeWrapper}>
            <Image sx={styles.closeIcon} src='/icon_close.svg' onClick={handleClose} />
          </Flex>
          <Flex sx={styles.trackContainer}>
            <InfiniteHorizontalLoop
              animation="slideLeft"
              interval={20}
              mx={['6px', '9px']}
              image={'/promotion_popup.png'}
            />
          </Flex>
          <Box sx={styles.newBadge}>NEW</Box>
          <Text sx={styles.title}>
            {nl2br(`‘새로운 커뮤니티의 기준’\n넷플연가가 다시 씁니다`)}
          </Text>
          <Text sx={styles.description} mt={['8px', '14px']}>
            좋은 커뮤니티의 핵심은 사람입니다.
          </Text>
          <Text sx={styles.description}>
            3개월간 멤버 <b>5,000명+</b>을 직접 만난 실험과 도전!
          </Text>
          <Flex px='24px' mt={['16px', '24px']} flexDirection='column'>
            <Flex sx={styles.detailButton} onClick={handleClickDetail}>
              자세히 보기
            </Flex>
          </Flex>
          <Flex sx={styles.bottomWrapper}>
            <Flex sx={styles.closeForSevenDaysButton} onClick={handleCloseForSevenDays}>
              7일간 보지 않기
            </Flex>
            <Flex sx={styles.closeText} onClick={handleClose}>
              닫기
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

const styles: StyleObject = {
  bottomWrapper: {
    justifyContent: ['space-between', 'center'],
    alignItems: 'center',
    p: ['18px 24px', '0px'],
    mt: '20px',
    borderTop: ['1px solid #EBEBEB', 'none'],
  },
  closeWrapper: {
    p: '20px',
    justifyContent: 'flex-end',
    display: ['none !important', 'flex !important'],
  },
  closeText: {
    color: '#505050',
    fontSize: '14px',
    fontWeight: '700',
    cursor: 'pointer',
    display: ['flex !important', 'none !important'],
  },
  closeIcon: {
    cursor: 'pointer',
    border: '1px solid #F0F0F0',
    borderRadius: '100%',
    bg: '#F5F5F5',
    p: '6px',
    width: '42px',
    height: '42px',
  },
  newBadge: {
    p: '6px 12px',
    fontSize: '12px',
    fontWeight: '700',
    border: '1px solid #D4D4D4',
    borderRadius: '120px',
    width: 'fit-content',
    mx: 'auto',
    mb: ['8px', '12px'],
    mt: '24px',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 99999999,
    display: 'flex',
    alignItems: ['flex-end', 'center'],
    justifyContent: 'center',
    transition: 'opacity 0.3s ease-in-out',
  },
  pcContainer: {
    width: '560px',
    height: '645px',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: 'white',
    borderRadius: '24px',
    position: 'relative',
  },
  mobileContainer: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '24px 24px 0 0',
    transition: 'transform 0.3s ease-in-out',
    pt: '36px',
  },
  content: {
    width: '100%',
    flexDirection: 'column',
  },
  title: {
    color: 'black',
    fontSize: ['24px', '30px'],
    fontWeight: '700',
    lineHeight: '1.4',
    textAlign: 'center',
  },
  description: {
    color: 'black',
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    textAlign: 'center',
    lineHeight: '1.5',
  },
  detailButton: {
    alignItems: 'center',
    width: '100%',
    height: ['48px', '65px'],
    backgroundColor: 'black',
    color: 'white',
    borderRadius: ['3px', '100px'],
    fontSize: ['16px', '18px'],
    fontWeight: '700',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  closeForSevenDaysButton: {
    alignItems: 'center',
    fontSize: ['14px', '15px'],
    color: '#505050',
    textAlign: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    textDecoration: 'underline',
  },
  trackContainer: {
    position: 'relative',
    width: '100%',
    height: ['120px', '180px'],
    overflow: 'hidden',
  },
}; 