import Link from 'next/link';
import React from 'react';
import { Box, SxStyleProp } from 'rebass';
import { StyleObject } from 'types/styles';
import { UrlObject } from 'url';

interface LinkWrapperProps {
  children?: any;
  href: string | UrlObject;
  sx?: SxStyleProp;
  target?: string;
  zIndex?: number;
}

export const LinkWrapper = ({ children, sx, href, target, zIndex }: LinkWrapperProps) => (
  <Link
    href={href}
    target={target}
    style={{ textDecoration: 'none', zIndex: zIndex || undefined }}>
    <Box sx={href ? { ...sx, ...styles.link } : { ...sx, ...styles.linkDisabled }}>{children}</Box>
  </Link>
);

const styles: StyleObject = {
  link: {
    cursor: 'pointer',
    textDecoration: 'none',
  },
  linkDisabled: {
    pointerEvents: 'none',
  },
};
