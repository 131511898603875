import React, { useEffect, useRef } from 'react';
import { Flex, Image } from 'rebass';
import { StyleObject } from 'types/styles';

interface InfiniteHorizontalLoopProps {
  animation: string;
  interval: number;
  image: string;
  mx?: string | string[];
  delay?: number;
  p?: string;
}

export const InfiniteHorizontalLoop = ({
  animation,
  interval,
  image,
  mx,
  delay = 0,
  p,
}: InfiniteHorizontalLoopProps) => {
  const loopRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (loopRef.current) {
      loopRef.current.style.animationDelay = `${delay}s`;
    }
  }, [delay]);

  return (
    <Flex
      ref={loopRef}
      sx={{
        animation: `${animation} ${interval}s infinite linear`,
        height: '100%',
        p,
        position: 'absolute',
        width: 'max-content',
      }}>
      <Image
        sx={{
          ...styles.image,
          mx,
        }}
        src={image}
      />
      <Image
        sx={{
          ...styles.image,
          mx,
        }}
        src={image}
      />
    </Flex>
  );
};

const styles: StyleObject = {
  image: {
    height: '100%',
    width: 'auto',
  },
  '@keyframes slideLeft': {
      '0%': {
          transform: 'translateX(0)',
      },
      '100%': {
          transform: 'translateX(-50%)',
      }
  },
};
