import { atom } from 'recoil';

export const signInModalVisibleState = atom({
  default: false,
  key: 'signInModalVisibleState',
});

export const addPromptModalVisibleState = atom({
  default: false,
  key: 'addPromptModalVisibleState',
});

export const addPaymentModalVisibleState = atom({
  default: false,
  key: 'addPaymentModalVisibleState',
});

export const benefitModalVisibleState = atom({
  default: false,
  key: 'benefitModalVisibleState',
});

export const answerModalInfo = atom({
  default: {
    answer: '',
    question: '',
    visible: false,
  },
  key: 'answerModalInfo',
});

export const IsEditState = atom({
  default: false,
  key: 'IsEditState',
});

export const EditPromptState = atom({
  default: null,
  key: 'EditPromptState',
});

export const SearchModalState = atom({
  default: false,
  key: 'SearchModalState',
});

export const FilterModalState = atom({
  default: false,
  key: 'FilterModalState',
});

export const NotificationModalState = atom({
  default: false,
  key: 'NotificationModalState',
});

export const RoleStatusModalState = atom({
  default: false,
  key: 'RoleStatusModalState',
});

export const UploadModalInfo = atom({
  default: {
    documentType: '',
    imgSrc: '',
    paycheckId: undefined,
    visible: false,
  },
  key: 'UploadModalInfo',
});

export const InviteModalInfo = atom({
  default: {
    metMeetupTitle: '',
    notificationTemplateTitle: '',
    receiver: '',
    receiverId: '',
    sender: '',
    visible: false,
  },
  key: 'InviteModalInfo',
});

export const NewUserCount = atom({
  default: 0,
  key: 'NewUserCount',
});
