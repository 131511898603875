import { AxiosRequestConfig } from 'axios';
import { RealtimeUserCountResponse } from 'types/interface';
import { BaseApi } from './BaseApi';

class StatApi {
  getRealtimeUserCount = async () => {
    const requestConfig: AxiosRequestConfig = {};
    const res = await BaseApi.get<RealtimeUserCountResponse>(
      `/v2/nfyg/realtime-user-count`,
      requestConfig,
    );
    return res.data;
  };
}

const StatApiClass = new StatApi();
export { StatApiClass as StatApi };
